import Banner from "./components/banner";
import Benefits from "./components/benefit";
import GetStarted from "./components/getStarted";
import HowDoesITWork from "./components/howdoes";

const HomePage = () => {
    
    return ( 
        <>
        <Banner />
        <Benefits />
        <HowDoesITWork />
        <GetStarted />
        </>
     );
}
 
export default HomePage;