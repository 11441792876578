import Layout from 'pages/components/layout';
import './App.css';
import Header from './pages/components/header';
import { Route, Routes } from 'react-router-dom';
import HomePage from 'pages/home';
import Footer from 'pages/components/footer';
import Missing from 'pages/components/404';
function App() {
  return (
    <>
        <Header />
        <Routes>
          <Route path = "" element = {<Layout />}>
                <Route path="/" element ={ <HomePage /> } />
                <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
        <Footer />
    </>
  );
}

export default App;
