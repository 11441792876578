import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <>
    <footer className="footer">
	    <div className="container py-5 mb-3">
		    <div className="row">
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">Products</h4>
				    <ul className="list-unstyled">
					    <li><Link className="text-link" to="/">BULK SMS</Link></li>
					    <li><Link className="text-link" to="/">Payments</Link></li>
					    <li><Link className="text-link" to="/">E-commerce</Link></li>
					    <li><Link className="text-link" to="/">Business Management</Link></li>
					    
				    </ul>
			    </div>
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">Developers</h4>
				    <ul className="list-unstyled">
					    <li><Link className="text-link" to="/">Integrations</Link></li>
					    <li><Link className="text-link" to="/">APIs</Link></li>
					    <li><Link className="text-link" to="/">FAQs</Link></li>
					    <li><Link className="text-link" to="/">Support</Link></li>
				    </ul>
			    </div>
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">Resources</h4>
				    <ul className="list-unstyled">
					    <li><Link className="text-link" to="/">GitHub</Link></li>
					    <li><Link className="text-link" to="/">Docs</Link></li>
					    <li><Link className="text-link" to="/">General Support</Link></li>
					    <li><Link className="text-link" to="/">Community</Link></li>
				    </ul>
			    </div>
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">OTAM</h4>
				    <ul className="list-unstyled">
					    <li><Link className="text-link" to="/">About Us</Link></li>
					    <li><Link className="text-link" to="/">Contact Us</Link></li>
					    <li><Link className="text-link" to="/">Privacy</Link></li>
					    <li><Link className="text-link" to="/">Terms of Services</Link></li>
				    </ul>
		            
			    </div>
		    </div>
	    </div>
	    <div className="container">
		    <hr />
	    </div>
	    {/* <div className="newsletter-section py-5">
		    <div className="container text-center">
			    <h4 className="mb-2">Join Our Newsletter</h4>
			    <div className="section-intro mb-2">Sign up to receive latest updates, features, and news!</div>
		        <form className="signup-form row gx-1 justify-content-center pt-3">
                    <div className="col-12 col-md-auto">
                        <label className="sr-only" for="semail">Email address</label>
                        <input type="email" id="semail" name="semail1" className="form-control semail" placeholder="Enter email" />
                    </div>
                    <div className="col-12 col-md-auto mt-2 mt-md-0">
                    <button type="submit" className="btn btn-primary">Sign Up</button>
                    </div>
                </form>
		    </div>
	    </div> */}
	    <div className="footer-bottom text-center pb-5">
	        <small className="copyright">OTAM LTD &copy; 2023</small>
	        {/* <ul className="social-list list-unstyled pt-4 mb-0">
			    <li className="list-inline-item"><a href="#"><i className="fab fa-github fa-fw"></i></a></li> 
	            <li className="list-inline-item"><a href="#"><i className="fab fa-twitter fa-fw"></i></a></li>
	            <li className="list-inline-item"><a href="#"><i className="fab fa-slack fa-fw"></i></a></li>
	            <li className="list-inline-item"><a href="#"><i className="fab fa-product-hunt fa-fw"></i></a></li>
	            <li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f fa-fw"></i></a></li>
	            <li className="list-inline-item"><a href="#"><i className="fab fa-instagram fa-fw"></i></a></li>
	        </ul> */}
	    </div>
    </footer>
        </>
     );
}
 
export default Footer;