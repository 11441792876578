import { Link } from "react-router-dom";

const Benefits = () => {
    return ( 
        <>
    <section className="benefits-section theme-bg-light py-5">
	    <div className="container">
		    <h3 className="mb-3 text-center font-weight-bold section-title">Our Major Services And Products</h3>
		    <div className="mb-5 text-center section-intro">Develop and Integrate into your own platforms</div>
		    <div className="row">
		        <div className="item col-12 col-md-6 col-lg-3">
				    <div className="item-inner rounded">
					    <div className="icon-holder text-center mx-auto mb-3">
					        <i className="fas fa-shapes text-danger"></i>
					    </div>
					    <h5 className="mb-3">OTAM Business Manager</h5>
					    <div>Manage Your business and clients, send bulk sms and accept payments online and offline, manage employees, send bulk emails, Inventory and POS management.</div>
					    <div className="mt-2">
                            <Link className="text-link" to="/products">Learn more &rarr;</Link>
                            </div>
				    </div>
			    </div>
			    
			    <div className="item col-12 col-md-6 col-lg-3">
				    <div className="item-inner rounded">
					    <div className="icon-holder text-center mx-auto mb-3">
					        <i className="fas fa-angle-double-right text-danger"></i>
					    </div>
					    <h5 className="mb-3">OTAM E-commerce</h5>
					    <div>Sign up to use our E-commerce platform to adveritise and sell your products, we also provide business integration of ecommerce api to external products. </div>
					    <div className="mt-2"><Link className="text-link" to="/product">Learn more &rarr;</Link></div>
				    </div>
			    </div>
			    
			    <div className="item col-12 col-md-6 col-lg-3">
				    <div className="item-inner rounded">
					    <div className="icon-holder text-center mx-auto mb-3">
					        <i className="fas fa-wrench text-danger"></i>
					    </div>
					    <h5 className="mb-3">Custom Software Development</h5>
					    <div>Do you need a custom software for your company, make an order, get mobile, web, desktop application at an affordable price.</div>
					    <div className="mt-2"><Link className="text-link" to="/services">Learn more &rarr;</Link></div>
				    </div>
			    </div>
			    
			    <div className="item col-12 col-md-6 col-lg-3">
				    <div className="item-inner rounded">
					    <div className="icon-holder text-center mx-auto mb-3">
					        <i className="fas fa-book text-danger"></i>
					    </div>
					    <h5 className="mb-3">Networking And Hardware</h5>
					    <div>Our team also provides tech support to make sure you get your Hardware and connected to the worldwide web </div>
					    <div className="mt-2"><Link className="text-link" to="/services">View More Info &rarr;</Link></div>
				    </div>
			    </div>
			    
		    </div>
		    <div className="pt-3 text-center">
			    <Link className="btn btn-light" to="/about">View More About OTAM</Link>
		    </div>
	    </div>
	    
    </section>
        </>
     );
}
 
export default Benefits;