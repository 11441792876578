import BannerImage from "assets/images/promo-figure-alt.svg";
import { Link } from "react-router-dom";
const Banner = () => {
    return ( 
        <>
        <section className="hero-section py-3 py-md-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pt-3 mb-5 mb-lg-0">
                        <h1 className="site-headline font-weight-bold mb-3">Sign Up To Use All Our Platforms.</h1>
                        <div className="site-tagline mb-4">Designed For Small and Large Business, Manage Your Business Using Our Highly Scaling Softwares.</div>
                        <div className="cta-btns mb-lg-3">
                            <Link className="btn btn-primary me-2 mb-3" to="/sms">OTAM shop</Link>
                            <Link className="btn btn-secondary mb-3" to="/pay">OTAM Business</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 text-center">
                        <img className="hero-figure mx-auto" src={ BannerImage } alt="" />
                    </div>
                </div>
            </div>
        </section>
        </>
     );
}
 
export default Banner;