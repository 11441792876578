import { Link } from "react-router-dom";
import Logo from "assets/logo/otam-logo.png";
import 'bootstrap/dist/js/bootstrap.bundle.min';
const Header = () => {
	
    return ( 
<>
    <header className="header fixed-top">	    
        <div className="branding">
            <div className="container position-relative">
				<nav className="navbar navbar-expand-lg" >
                    <div className="site-logo">
                        <Link className="navbar-brand" to="/">
                            <img className="logo-icon me-2" src={Logo} height={50} alt="logo" />
                            </Link>
                        </div>    
						<button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
							<span> </span>
							<span> </span>
							<span> </span>
						</button>
					
					<div className="collapse navbar-collapse py-3 py-lg-0" id="navigation">
						<ul className="navbar-nav ms-lg-auto">
							<li className="nav-item me-lg-4">
							    <Link className="nav-link" to="/">Home</Link>
							</li>
							<li className="nav-item me-lg-4">
							   <Link className="nav-link" to="/about">About Us</Link>
							</li>
							<li className="nav-item me-lg-4">
							    <Link className="nav-link" to="/products">Products</Link>
							</li>
							<li className="nav-item me-lg-4">
							   <Link className="nav-link" to="/services">Services</Link>
							</li>
                            <li className="nav-item me-lg-4">
							   <Link className="nav-link" to="/blog">Blog</Link>
							</li>
                            <li className="nav-item me-lg-4">
							   <Link className="nav-link" to="/contact">Contact Us</Link>
							</li>
							<li className="nav-item me-lg-0 mt-3 mt-lg-0">
							   <Link className="btn btn-primary rounded-0 text-white" to="/apply">Get Started</Link>
							</li>
						</ul>
					</div>
				</nav>
            </div>
        </div>
    </header>
</>
     );
}
 
export default Header;