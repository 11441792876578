import { Link } from "react-router-dom";

const GetStarted = () => {
    return ( 
        <>
    <section className="cta-section text-center py-5 theme-bg-dark position-relative">
	    <div className="theme-bg-shapes-right"></div>
	    <div className="theme-bg-shapes-left"></div>
	    <div className="container">
		    <h3 className="mb-3 text-white mb-3">Get Started in No Time</h3>
		    <div className="section-intro text-white mb-3 single-col-max mx-auto">Sign up for the account to allow your business to grow.</div>
		    <div className="pt-3 text-center">
			    <Link className="btn btn-light" to="/started">Get Started<i className="fas fa-arrow-alt-circle-right ms-2"></i></Link>
		    </div>
	    </div>
    </section>
        </>
     );
}
 
export default GetStarted;